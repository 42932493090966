import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg0 from "../assets/img/247care.png";
import projImg1 from "../assets/img/tran.png";
import projImg2 from "../assets/img/domestic.png";
import projImg3 from "../assets/img/surgery.png";
import projImg4 from "../assets/img/cancer1.png";
import projImg5 from "../assets/img/autism.png";
import projImg6 from "../assets/img/strok3.png";
import projImg7 from "../assets/img/multiple.png";
import projImg8 from "../assets/img/personalcare.png";
import projImg9 from "../assets/img/sunsun.png";
import projImg10 from "../assets/img/cost.png";
import projImg11 from "../assets/img/brii.png";
import projImg12 from "../assets/img/emplyee.png";
import projImg13 from "../assets/img/viky.png";
import projImg15 from "../assets/img/syd.png";
import projImg16 from "../assets/img/homebri.png";
import projImg17 from "../assets/img/homegold.png";
import projImg18 from "../assets/img/homesun1.png";
import projImg19 from "../assets/img/homesy.png";
import projImg20 from "../assets/img/homevv.png";
import projImg21 from "../assets/img/cleaning.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';
import './projects.css'

export const Projects = () => {

  const projects1 = [
    {
      title: "Disability Transport Services",
      description: "We understand that getting around can be tough, especially if you’re living with a disability. That’s why we’re proud to offer our disability transport services, which are designed to help you get where you need to go without any hassle. From door-to-door service to wheelchair-accessible vehicles, we’ve got you covered.",
      imgUrl: projImg1,
    },
    {
      title: "Domestic Assistance",
      description: "Getting your home in order can be a daunting task, but at Aspire Care, we’re here to help . Whether you need a one-off cleaning, laundry, meals or regular assistance with the laundry and meal prep, our domestic assistance services are designed to make your life easier. So why not sit back, relax and let us take care of the hard work for you?",
      imgUrl: projImg2,
    },
    {
      title: "Personal Care",
      description: "Aspire Care is here to help. We provide oral hygiene, showering, and grooming assistance for those who need it. Our services are designed to make life easier for both you and your loved ones. You can trust us to help you stay clean, healthy, and looking your best – no matter what life throws your way.",
      imgUrl: projImg8,
    },

    {
      title: "Autism Home Care",
      description: "Autism can be a difficult condition to manage, especially when it comes to taking care of your loved one. That’s where we come in – Aspire Care is here to provide you with the support and resources you need to take care of your autistic family member. With our help, you can rest assured that your loved one is receiving the best possible care – even if you’re not able to be there yourself.",
      imgUrl: projImg5,
    },
    
    {
      title: "Home Care for Stroke Patients",
      description: "Aspire Care understands that stroke patients need more than just medical care. They need support and companionship to help them through the recovery process. With our home care services, you’ll have a team of compassionate caregivers to help you feel comfortable and connected in your own home",
      imgUrl: projImg6,
    },
    

    {
      title: "Multiple Sclerosis Home Care",
      description: "We understand how difficult it can be to care for someone who is suffering from MS. That’s why we offer home care services that are designed specifically to help those living with MS. From providing personal assistance and companionship to helping with household tasks, our caregivers are here to make life a little easier for you and your loved one.",
      imgUrl: projImg7,
    },
    {
      title: "After Surgery Home Care",
      description: "Aspire Care provides top-notch home care services to those who are recovering from surgery. Let us take care of everything for you – from providing meals to cleaning up after you. You can relax and focus on getting better while we take care of everything else.",
      imgUrl: projImg3,
    },
    {
      title: "At-Home Cancer Care",
      description: "We understand that finding the right care for your loved one can be difficult. That’s why we offer at-home cancer care services that are tailored to meet each individual’s needs. From providing emotional support to helping with everyday tasks, our medical team is here to help make life a little easier during this difficult time.",
      imgUrl: projImg4,
    },
    {
      title: "Cleaning",
      description: "Whether you’re maintaining your independence at home or need an extra pair of hands, we can help with our specialised NDIS cleaning services.",
      imgUrl: projImg21,
    },
    {
      title: "Access to Employment and Education",
      description: "Aspire Care is proud to assist NDIS participants to access and maintain employment or higher education.",
      imgUrl: projImg12,
    }

  ]

  const projects2 = [
    
    {
      title: "Home Care Providers in Brisbane",
      description: "At Aspire Care, we understand that many people feel uncomfortable asking for help. That’s why our team of highly qualified and experienced carers will work closely with you and your family to create a personalised care plan that meets your individual needs. From providing companionship and personal care to helping with household tasks, we’re here to help make life easier for you.",
      imgUrl: projImg16,
    },
    {
      imgUrl: projImg17,
    },
    {
      imgUrl: projImg18,
    },
    {
      imgUrl: projImg19,
    },
    
    {
      imgUrl: projImg20,
    },
  ]

  const projects = [

    {
      title: "24/7 Care Services",
      description: "Our caregivers at Aspire Care are experienced and qualified, so you can trust them to take care of your loved ones around the clock.",
      imgUrl: projImg0,
    },
    {
      title: "Disability Care Brisbane",
      description: "Exceptional Care Delivered at Home to NDIS Participants in Brisbane",
      imgUrl: projImg11,
    },
    {
      title: "Disability Care Sunshine Coast",
      description: "At Aspire Care, we have assembled a team of the most experienced and compassionate disability carers on the Sunshine Coast. Our goal is to provide unparalleled services to people with disabilities, so they can live their lives to the fullest potential.",
      imgUrl: projImg9,
    },
    {
      imgUrl: projImg10,
    },
    
    {
      imgUrl: projImg13,
    },
    {
      imgUrl: projImg15,
    },

    
    
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Personalised in-home care assistance and disability support services, perfectly matched to you.</h2>
                
                <p>CONTACT US TO KNOW HOW WE CAN MAKE A DIFFERENCE IN YOUR LIFE</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Disability</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Services</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Home Care</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row >
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                onClick="#contact"
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}

export default Projects;