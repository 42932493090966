import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/ndis.png";
import headerImg1 from "../assets/img/ndis3.png";
import 'animate.css';
import './about.css'
import TrackVisibility from 'react-on-screen';

export const Fund = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const period = 2000;

  return (
    <section className="about" id="fund">
      
      <Container>
        <h1 id="aboutus">NDIS Funding</h1>
        <p>The National Disability Insurance Scheme (NDIS) provides funding to eligible people based on their individual needs. Every NDIS participant has an individual plan that lists their goals and the funding they have received.</p>
        <p> NDIS participants use their funding to purchase supports and services that will help them pursue their goals. Everyone has different goals but they could include things like getting and keeping a job, making friends or participating in a local community activity. NDIS participants control the support they receive, when they receive it, and who provides it.</p>
        <Row className="aligh-items-center">
        
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 id="aspire">Core Funding</h2>
                  <div id="para">
                    <p>This pays for essential supports that help you with everyday life, such as personal care, community access, and transport.</p>
                  </div>
                  <h2 id="aspire">Capacity Building</h2>
                  <div id="para">
                    <p>Capacity Building Supports help build your independence and skills to help you pursue your goals. Unlike your Core Supports budget, your Capacity Building Supports budget cannot be moved from one support category to another. Funding can only be used to purchase approved individual supports that fall within that Capacity Building category.</p>
                  </div>
                  <h2 id="aspire">Capital Support</h2>
                  <div id="para">
                    <p>The Capital Support budget relates to investments such as assistive technology equipment, home or vehicle modifications, or specialist disability equipment. The funding for support will include assessment, delivery, set-up, adjustment and maintenance costs as needed.</p>
                  </div>
                  
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Aspire We Care"/>
                  <img src={headerImg1} alt="Aspire We Care"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Fund;