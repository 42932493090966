import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import projImg14 from "../assets/img/care.png";
import 'animate.css';
import './about.css'
import TrackVisibility from 'react-on-screen';

export const About = () => {

  return (
    <section className="about" id="about">
      
      <Container>
        <h1 id="aboutus">About Us</h1>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div id="careimg" className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={projImg14} alt="Aspire We Care"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 id="aspire">Our Progress is in Our Client's Progress</h2>
                  <div id="para">
                    <p>Aspire Care offers a range of disability services including home care and personal services to support your choice to live independently, social activities and support to help you get out and about and specialist services including nursing and allied health.</p>
                  </div>
                  <p id="p2">We have intentionally built qualified teams who live and work in your local area that are dedicated to serving you, in your home, and in your local community.</p>
                  <p>We pride ourselves in being the best provider of disability and home care services across the greater Brisbane area, Sydney, Gold Coast, Sunshine Coast, and Victoria. Aspire Care continually receives excellent feedback from the people we support and their families. We help to establish and develop independence, by offering high-quality services to NDIS participants. We set ourselves apart from other service providers by being committed to the person-centred approach.</p>
              </div>}
            </TrackVisibility>
          </Col>
          
        </Row>
      </Container>
    </section>
  )
}

export default About;