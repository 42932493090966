import React, {useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import TrackVisibility from 'react-on-screen';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './contact.css'
import 'animate.css';


export const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_ni74yis","template_l870sfm", form.current, '4UoPzVw-0B7dkRrnm')
      .then((result) => {
          console.log(result.text);
          toast.success("Thanks! Email Sent.");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
          toast.error("Opps! Check Your Connection.");
      });
  }

  

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
        <Col size={12} md={6}>
            <TrackVisibility>
              <div class="elementor-element elementor-element-a10c6c5 contact-info-link elementor-invisible elementor-widget elementor-widget-elementskit-heading" data-id="a10c6c5" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="elementskit-heading.default">
                <div class="elementor-widget-container">
                    <div class="ekit-wid-con" >
                        <div class="ekit-heading elementskit-section-title-wraper text_left   ekit_heading_tablet-   ekit_heading_mobile-">
                            <h2 class="ekit-heading--title elementskit-section-title "> Contact Info </h2>
                        </div>
                    </div>		
                </div>
              </div>
          <div class="container1">
              <div class="elementor-element elementor-element-ec5336d elementor-widget elementor-widget-heading" data-id="ec5336d" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                      <h3 class="elementor-heading-title elementor-size-default">Mail</h3>
                      <p><a href="mailto:admin@basichomecare.com.au">info@acdisability.com</a></p>		
                  </div>
              </div>
              
              <div class="elementor-element elementor-element-7b71f97 elementor-widget elementor-widget-heading" data-id="7b71f97" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                      <h3 class="elementor-heading-title elementor-size-default">Phone</h3>
                      <a href="tel:0735680831">0402221445</a>	
                  </div>
              </div>
              
          </div>

              <div class="contaner2">

                    <div class="elementor-element elementor-element-091ae1b elementor-widget elementor-widget-heading" data-id="091ae1b" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h3 class="elementor-heading-title elementor-size-default">Address</h3>
                                <p><a>Balmoral Street, Kuraby, 4112 QLD</a></p>
                                <p><a>Hinchen Street, Guildford, NSW 2161</a></p>		
                            </div>
                      </div>

                        <div class="elementor-element elementor-element-22d1a04 elementor-widget elementor-widget-heading" data-id="22d1a04" data-element_type="widget" data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <h3 class="elementor-heading-title elementor-size-default">Office Hours</h3>
                                <p>Moday to Friday: 9am to 5pm<br /><br /></p>		
                            </div>
                        </div>
                    </div>
            </TrackVisibility>
        </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                <form  ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="firstName" placeholder="Name" required/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="subject"  placeholder="Subject" required/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="email" placeholder="Email Address" required/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="phone" placeholder="Phone No." required/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="message" placeholder="Message" required />
                      <button type="submit">Send</button>
                    </Col>
                    
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact;

