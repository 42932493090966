import React from "react";
import { Col } from "react-bootstrap";
import './cards.css'

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt=""/>
        <div className="proj-txtx">
          <h4><a href="#service" id="tag">{title}</a></h4>
          <span className="text">{description}</span>
        </div>
      </div>
    </Col>
  )
}

export default ProjectCard;